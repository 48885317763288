import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PublishButton from './PublishButton/PublishButton'

export default function RightTopbar() {
  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
      width={232}
      height={48}
      paddingH={16}
      gap={8}
      direction={'row'}
      zIndex={1}
    >
      <PublishButton />
    </PanelContainer>
  )
}
