import {
  Combinator,
  MediaQuery,
  SelectorMode,
  SelectorPseudo,
  StyleSelector,
} from './types'

export class StyleSelectorImpl implements StyleSelector {
  private modes: SelectorMode[]
  private values: string[]
  private combinators: Combinator[]
  private pseudos: SelectorPseudo[]
  private media: MediaQuery | null

  constructor(
    modes: SelectorMode[] = [],
    values: string[] = [],
    combinators: Combinator[] = [],
    pseudos: SelectorPseudo[] = [],
    media: MediaQuery | null = null
  ) {
    this.modes = modes
    this.values = values
    this.combinators = combinators
    this.pseudos = pseudos
    this.media = media
    this.sortPseudo()
  }

  getModes = (): SelectorMode[] => {
    return this.modes
  }

  getValues = (): string[] => {
    return this.values
  }

  getCombinators = (): Combinator[] => {
    return this.combinators
  }

  getPseudos = (): SelectorPseudo[] => {
    return this.pseudos
  }

  getMedia = (): MediaQuery | null => {
    return this.media
  }

  equals = (o: StyleSelector): boolean => {
    const modes = o.getModes()
    const values = o.getValues()
    const combinators = o.getCombinators()
    const pseudos = o.getPseudos()
    const media = o.getMedia()
    if (this.modes.length !== modes.length) return false
    if (this.values.length !== values.length) return false
    if (this.combinators.length !== combinators.length) return false
    for (let i = 0; i < this.modes.length; i++) {
      if (this.modes[i] !== modes[i]) return false
      if (this.values[i] !== values[i]) return false
      if (this.combinators[i] !== combinators[i]) return false
    }
    if (this.pseudos.length !== pseudos.length) return false
    for (let i = 0; i < this.pseudos.length; i++) {
      if (this.pseudos[i] !== pseudos[i]) return false
    }
    if (this.media) {
      if (!media) return false
      if (this.media.clause.mode !== media.clause.mode) return false
      if (this.media.clause.size !== media.clause.size) return false
    } else {
      if (media) return false
    }
    return true
  }

  compareTo = (o: StyleSelector): number => {
    if (this.media && !o.getMedia()) return 1
    if (!this.media && o.getMedia()) return -1
    if (this.media && o.getMedia()) {
      if (this.media.clause.size > o.getMedia()!.clause.size) return -1
      if (this.media.clause.size < o.getMedia()!.clause.size) return 1
    }
    if (this.modes.length > o.getModes().length) return 1
    if (this.modes.length < o.getModes().length) return -1
    for (let i = 0; i < this.modes.length; i++) {
      if (this.modes[i] > o.getModes()[i]) return 1
      if (this.modes[i] < o.getModes()[i]) return -1
      if (this.values[i] > o.getValues()[i]) return 1
      if (this.values[i] < o.getValues()[i]) return -1
      if (this.combinators[i] > o.getCombinators()[i]) return 1
      if (this.combinators[i] < o.getCombinators()[i]) return -1
    }
    return 0
  }

  hasMode = (mode: SelectorMode, value: string): boolean => {
    for (let i = 0; i < this.modes.length; i++) {
      const currentMode = this.modes[i]
      const currentValue = this.values[i]
      if (currentMode === mode && currentValue === value) return true
    }
    return false
  }

  toWrappedString = (content: string): string => {
    if (this.media) {
      return `@media (${this.media.clause.mode}-width: ${this.media.clause.size}px) { ${this.toPrefixString()} { ${content} } }`
    } else {
      return `${this.toPrefixString()} { ${content} }`
    }
  }

  private toPrefixString = (): string => {
    let selector = ''
    for (let i = 0; i < this.modes.length; i++) {
      selector += this.modeToPrefix(this.modes[i])
      selector += this.values[i]
      if (this.combinators[i]) {
        selector += this.combinators[i]
      }
    }
    for (const pseudo of this.pseudos) {
      selector += this.pseudoToPrefix(pseudo)
    }
    return selector
  }

  private modeToPrefix = (mode: SelectorMode): string => {
    switch (mode) {
      case 'class':
        return '.'
      case 'id':
        return '#'
      case 'tag':
        return ''
    }
  }

  private pseudoToPrefix = (pseudo: SelectorPseudo): string => {
    switch (pseudo) {
      case 'active':
        return ':active'
      case 'hover':
        return ':hover'
      case 'focus':
        return ':focus'
      case 'after':
        return '::after'
      case 'before':
        return '::before'
    }
  }

  private sortPseudo = (): void => {
    this.pseudos.sort((a, b) => this.getPseudoIndex(a) - this.getPseudoIndex(b))
  }

  private getPseudoIndex = (pseudo: SelectorPseudo): number => {
    switch (pseudo) {
      case 'hover':
        return 0
      case 'active':
        return 1
      case 'focus':
        return 2
      case 'before':
        return 3
      case 'after':
        return 4
    }
  }
}
