import { formatComponentName } from 'application/export/utils'
import { ReadOnlyNode } from 'application/node'
import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import useToast from 'hooks/editor/useToast'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useEffect, useRef, useState } from 'react'
import { DesignColor } from 'themes'

interface PublishPopupProps {
  selected: ReadOnlyNode[]

  publishing: boolean
  publish: () => void

  onClose: () => void
}

export default function PublishPopup({
  selected,
  publishing,
  publish,
  onClose,
}: PublishPopupProps) {
  const toast = useToast()
  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({ ref: popupRef, close: onClose })

  const [localPublishing, setLocalPublishing] = useState(false)
  const [publishingMessage, setPublishingMessage] = useState('Publishing.')

  const handleSubmit = () => {
    publish()
    setLocalPublishing(true)
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (localPublishing && publishing) {
      interval = setInterval(() => {
        setPublishingMessage((prev) => {
          const dots = prev.split('.').length - 1
          return `Publishing${'.'.repeat((dots % 3) + 1)}`
        })
      }, 500)
    }

    if (localPublishing && !publishing) {
      toast('Successfully published!', 'success')
      setLocalPublishing(false)
      setPublishingMessage('Publishing.')
      if (interval) clearInterval(interval)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [localPublishing, publishing, toast])

  return (
    <Portal>
      <div
        ref={popupRef}
        style={{
          position: 'absolute',
          top: 64,
          right: 248,
          width: 232,
          border: `1px solid ${DesignColor('panelBorder')}`,
          borderRadius: 8,
          backgroundColor: DesignColor('panelBackground'),
          userSelect: 'all',
          pointerEvents: 'all',
        }}
      >
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode={'title'}>
              Publish
            </TextBlock>
            <IconBlock icon={'X'} onClick={onClose} />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode={'title'}>
              Components
            </TextBlock>
          </PanelRow>
          <ScrollContainer maxHeight={176}>
            {selected.map((node) => (
              <PanelRow key={node.getId()}>
                <TextBlock width={'fill'} mode={'label1'}>
                  {formatComponentPath(node.getBaseAttribute('name'))}
                </TextBlock>
              </PanelRow>
            ))}
          </ScrollContainer>
        </PanelSection>
        <PanelSection bottom={true}>
          <PanelRow align={'center'}>
            {!publishing && (
              <CTAButton
                width={'fill'}
                text={'Submit'}
                onClick={handleSubmit}
              />
            )}
            {publishing && (
              <TextBlock width={'fill'} mode={'title'}>
                {publishingMessage}
              </TextBlock>
            )}
          </PanelRow>
        </PanelSection>
      </div>
    </Portal>
  )
}

function formatComponentPath(name: string) {
  const formattedName = formatComponentName(name)
  return 'src/' + formattedName + '/' + formattedName + '.tsx'
}
