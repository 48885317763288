export type SelectorMode = 'id' | 'class' | 'tag'

export type SelectorPseudo = 'active' | 'hover' | 'focus' | 'after' | 'before'

export type Combinator = '<' | '>' | ' ' | '+' | '' | ','

export type MediaQuery = {
  type: 'media'
  clause: MediaQueryClause
}

export type MediaQueryClause = {
  mode: 'min' | 'max'
  size: number
}

export interface FontFace {
  getFamily: () => string
  getWeight: () => string
  getFormat: () => string
  getFileUrl: () => string
  toString(): string
}

export interface StyleSelector {
  getMedia: () => MediaQuery | null
  getModes: () => SelectorMode[]
  getValues: () => string[]
  getCombinators: () => Combinator[]
  getPseudos: () => SelectorPseudo[]
  equals: (o: StyleSelector) => boolean
  compareTo: (o: StyleSelector) => number
  hasMode: (mode: SelectorMode, value: string) => boolean
  toWrappedString: (content: string) => string
}

export interface Style {
  getSelector: () => StyleSelector
  getProperties: () => Partial<Properties>
  getProperty: <K extends keyof Properties>(k: K) => Properties[K] | null
  setProperty: <K extends keyof Properties>(k: K, v: Properties[K]) => void
  isEmpty: () => boolean
}

export interface StyleMap {
  get(selector: StyleSelector): Style
  getForMode(mode: SelectorMode, value: string): Style[]
  render(): string
}

export type Properties = {
  display:
    | 'block'
    | 'inline'
    | 'flex'
    | 'grid'
    | 'none'
    | 'contents'
    | 'inherit'

  position: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky'

  top: UnitSize | 'auto'
  right: UnitSize | 'auto'
  bottom: UnitSize | 'auto'
  left: UnitSize | 'auto'

  width: UnitSize | 'auto' | 'max-content' | 'min-content' | 'fit-content'
  height: UnitSize | 'auto' | 'max-content' | 'min-content' | 'fit-content'
  minWidth: UnitSize | 'auto'
  minHeight: UnitSize | 'auto'
  maxWidth: UnitSize | 'none'
  maxHeight: UnitSize | 'none'
  aspectRatio: number | 'auto'

  margin: UnitSize | 'auto'

  padding: UnitSize | Padding2 | Padding3 | Padding4 | 'auto'
  paddingTop: UnitSize | 'auto'
  paddingRight: UnitSize | 'auto'
  paddingBottom: UnitSize | 'auto'
  paddingLeft: UnitSize | 'auto'

  border: Border1 | Border2 | Border3 | 'none'
  borderColor: UnitColor
  borderTop: Border1 | Border2 | Border3 | 'none'
  borderRight: Border1 | Border2 | Border3 | 'none'
  borderBottom: Border1 | Border2 | Border3 | 'none'
  borderLeft: Border1 | Border2 | Border3 | 'none'

  borderRadius: UnitSize | UnitSize2 | UnitSize4 | 'inherit'

  flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  flexWrap: 'nowrap' | 'wrap' | 'wrap-reverse'
  justifyContent: 'start' | 'end' | 'center' | 'space-between'
  alignItems: 'start' | 'end' | 'center' | 'stretch'
  alignSelf: 'auto' | 'start' | 'end' | 'center' | 'stretch'
  gap: UnitSize

  flex: number | UnitFlex3 | UnitFlex3Auto | 'none'

  overflow: 'visible' | 'hidden' | 'scroll' | 'auto'

  zIndex: number

  objectFit: 'fill' | 'contain' | 'cover'
  objectPosition: 'top' | 'right' | 'bottom' | 'left' | 'center'

  fontFamily: string
  fontSize: UnitLength
  fontStyle: 'normal' | 'italic' | 'oblique'
  fontWeight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  lineHeight: UnitLength | 'normal'
  letterSpacing: UnitLength
  textAlign: 'left' | 'right' | 'center' | 'justify'
  textDecoration: 'none' | 'underline' | 'overline' | 'line-through'
  whiteSpace: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line'

  opacity: UnitPercent

  color: UnitColor
  background: UnitColor | UnitLinearGradient | ImageFunction
  backgroundPosition: 'top' | 'right' | 'bottom' | 'left' | 'center'
  backgroundSize: 'auto' | 'cover' | 'contain' | UnitSize2
  backgroundRepeat: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat'
  backgroundClip: 'text'
  webkitBackgroundClip: 'text'
  textFillColor: UnitColor
  webkitTextFillColor: UnitColor

  backdropFilter: FilterFunction[] | 'none'
  webkitBackdropFilter: FilterFunction[] | 'none'

  boxShadow: BoxShadow[] | 'none'

  cursor: 'auto' | 'default' | 'pointer' | 'text' | 'move' | 'not-allowed'

  transition: Transition | 'none' | 'inherit'
  transitionDuration: string | 'inherit'
  transitionProperty: string | 'inherit'
  transitionTimingFunction: TransitionTiming | 'inherit'

  transform: Transform
}

export const PropertyOrder: (keyof Properties)[] = [
  'display',

  'position',

  'top',
  'right',
  'bottom',
  'left',

  'width',
  'height',
  'minWidth',
  'minHeight',
  'maxWidth',
  'maxHeight',
  'aspectRatio',

  'margin',

  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',

  'border',
  'borderColor',
  'borderTop',
  'borderRight',
  'borderBottom',
  'borderLeft',

  'borderRadius',

  'flexDirection',
  'flexWrap',
  'justifyContent',
  'alignItems',
  'alignSelf',
  'gap',

  'flex',

  'overflow',

  'zIndex',

  'objectFit',
  'objectPosition',

  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'letterSpacing',
  'textAlign',
  'textDecoration',
  'whiteSpace',

  'opacity',

  'color',
  'background',
  'backgroundPosition',
  'backgroundSize',
  'backgroundRepeat',
  'backgroundClip',
  'webkitBackgroundClip',
  'textFillColor',
  'webkitTextFillColor',

  'backdropFilter',
  'webkitBackdropFilter',

  'boxShadow',

  'cursor',

  'transition',
  'transitionDuration',
  'transitionProperty',
  'transitionTimingFunction',

  'transform',
]

export type UnitLength = {
  value: number
  unit: 'px' | 'em' | 'rem' | 'vh' | 'vw'
}

export type UnitPercent = {
  value: number
  unit: '%'
}

export type UnitDeg = {
  value: number
  unit: 'deg'
}

export type Operators = '+' | '-' | '*' | '/'

export type CalcFunction = {
  operators: Operators[]
  values: (UnitLength | UnitPercent)[]
}

export type UnitSize = UnitLength | UnitPercent | CalcFunction
export type UnitSize2 = [UnitSize, UnitSize]
export type UnitSize3 = [UnitSize, UnitSize, UnitSize]
export type UnitSize4 = [UnitSize, UnitSize, UnitSize, UnitSize]

export type UnitFlex3 = [number, number, UnitLength]
export type UnitFlex3Auto = [number, number, 'auto']

export type UnitColor = UnitHex | UnitRGB

export type UnitLinearGradient = {
  angle: number
  stops: [UnitColor, number][]
}

export type UnitHex = string

export type UnitRGB = {
  r: number
  g: number
  b: number
  a: number
}

export type ImageFunction = {
  url: string
}

export type FilterFunction = FilterBlur | FilterBrightness
export type FilterBlur = {
  type: 'blur'
  value: UnitLength
}
export type FilterBrightness = {
  type: 'brightness'
  value: number
}

export type BoxShadow = {
  inset: boolean
  values: BoxShadow2 | BoxShadow3 | BoxShadow4
  color: UnitColor
}
export type BoxShadow2 = [UnitLength, UnitLength]
export type BoxShadow3 = [UnitLength, UnitLength, UnitLength]
export type BoxShadow4 = [UnitLength, UnitLength, UnitLength, UnitLength]

export type BorderStyle = 'solid' | 'dotted' | 'dashed'
export type Border1 = BorderStyle
export type Border2 = [BorderStyle, UnitLength]
export type Border3 = [BorderStyle, UnitLength, UnitColor]

export type Padding2 = UnitSize2
export type Padding3 = UnitSize3
export type Padding4 = UnitSize4

export type TransitionTiming =
  | 'linear'
  | 'ease'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'

export type Transition = {
  property: string
  duration: number
  timing: TransitionTiming
}

export type Transform = {
  x?: UnitLength | UnitPercent
  y?: UnitLength | UnitPercent
  rotate?: UnitDeg
  scale?: number
}
