import { CommandHandler } from 'application/client'
import { AttributePanel } from './attributePanel'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CodeExporter } from 'editor/export/code'
import { BackendService, Package } from 'application/service'
import { ReadOnlyNode } from 'application/node'
import { formatComponentName } from 'application/export/utils'

type PublishPanelBaseKeys = never

type PublishPanelStyleKeys = never

type PublishPanelState = {
  publishing: boolean
}

type PublishPanelHandlers = {
  publish: () => void
}

export class PublishPanel extends AttributePanel<
  PublishPanelState,
  PublishPanelHandlers,
  PublishPanelStyleKeys,
  PublishPanelBaseKeys
> {
  private exporter: CodeExporter
  private backend: BackendService
  private publishing: boolean

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    exporter: CodeExporter,
    backend: BackendService
  ) {
    super(commandHandler, action, document, documentSelection)
    this.exporter = exporter
    this.backend = backend
    this.publishing = false
  }

  getSettings = (): PublishPanelState => {
    return {
      publishing: this.publishing,
    }
  }

  getHandlers = (): PublishPanelHandlers => {
    return {
      publish: this.publish,
    }
  }

  private publish = (): void => {
    const selected = this.getNodes()
    if (selected.length === 0) return

    const exportedCode = this.exporter.exportLibrary(
      selected.map((node) => node.getId())
    )
    if (!exportedCode) return

    const indexFile = this.createIndexFile(selected)
    const library: Package = {
      name: 'repaint',
      files: [{ path: 'src/index.ts', content: indexFile }],
    }
    for (const file of exportedCode.files) {
      library.files.push(file)
    }

    this.publishing = true
    this.notifyListeners()

    this.backend.publishPackage(library).then(() => {
      this.publishing = false
      this.notifyListeners()
    })
  }

  private createIndexFile = (nodes: ReadOnlyNode[]): string => {
    let index = ''
    for (const node of nodes) {
      const name = node.getBaseAttribute('name')
      const componentName = formatComponentName(name)
      index += `export { ${componentName} } from './${componentName}/${componentName}'\n`
    }
    return index
  }
}
