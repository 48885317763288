import { WSCommand } from './types'

export enum ListenerType {
  ON_CONNECT_ERROR = 'connect_error',
  ON_OPEN = 'open',
  ON_CLOSE = 'close',
  ON_MESSAGE = 'message',
  ON_ERROR = 'error',
}

export class WebsocketConnection {
  private url: string
  private socket: WebSocket
  private messageQueue: object[]
  private isProcessing: boolean
  private isOpening: boolean
  private listeners: Record<string, Record<string, (object?: object) => void>>

  constructor(url: string) {
    this.url = url
    this.socket = {} as WebSocket
    this.messageQueue = []
    this.isProcessing = false
    this.isOpening = false
    this.listeners = {
      [ListenerType.ON_CONNECT_ERROR]: {},
      [ListenerType.ON_OPEN]: {},
      [ListenerType.ON_CLOSE]: {},
      [ListenerType.ON_MESSAGE]: {},
      [ListenerType.ON_ERROR]: {},
    }
  }

  connect() {
    if (this.isOpening) return
    this.isOpening = true

    this.socket = new WebSocket(this.url)

    this.socket.addEventListener(ListenerType.ON_CONNECT_ERROR, (error: any) => {
      console.debug('[Websocket] Connection error', error)
      Object.values(this.listeners[ListenerType.ON_CONNECT_ERROR]).forEach(
        (listener) => {
          listener()
        }
      )
    })

    this.socket.addEventListener(ListenerType.ON_OPEN, () => {
      console.debug('[WS] Connected')
      Object.values(this.listeners[ListenerType.ON_OPEN]).forEach(
        (listener) => {
          listener()
        }
      )
      this.isOpening = false
    })

    this.socket.addEventListener(ListenerType.ON_CLOSE, () => {
      console.debug('[WS] Disconnected')
      Object.values(this.listeners[ListenerType.ON_CLOSE]).forEach(
        (listener) => {
          listener()
        }
      )
    })

    this.socket.addEventListener(ListenerType.ON_MESSAGE, (data) => {
      this.addToQueue(JSON.parse(data.data))
    })

    this.socket.addEventListener(ListenerType.ON_ERROR, (error: any) => {
      console.debug('[Websocket] Error', error)
      Object.values(this.listeners[ListenerType.ON_ERROR]).forEach(
        (listener) => {
          listener()
        }
      )
    })
  }

  send(message: WSCommand): boolean {
    if (this.isOpen()) {
      this.socket.send(JSON.stringify(message))
      return true
    } else {
      console.debug('[Websocket] SKIPPING SEND, CLOSED', message)
      return false
    }
  }

  addListener(
    type: ListenerType,
    name: string,
    listener: (object?: object) => void
  ) {
    this.listeners[type][name] = listener
  }

  removeListener(type: ListenerType, name: string) {
    delete this.listeners[type][name]
  }

  isOpen() {
    return this.socket.readyState === WebSocket.OPEN
  }

  close() {
    this.socket.close()
  }

  private processQueue() {
    if (this.messageQueue.length === 0) {
      this.isProcessing = false
      return
    }
    this.isProcessing = true
    const message = this.messageQueue.shift()
    Object.values(this.listeners[ListenerType.ON_MESSAGE]).forEach(
      async (listener) => {
        listener(message)
      }
    )
    setTimeout(() => {
      this.processQueue()
    }, 0)
  }

  private addToQueue(message: object) {
    this.messageQueue.push(message)
    if (!this.isProcessing) {
      this.processQueue()
    }
  }
}
