import { ReadOnlyNode } from 'application/node'
import { Element } from '../html/types'
import { ReactComponentImpl } from './component'

export class ReactRenderer {
  render = (element: Element, node: ReadOnlyNode): string => {
    const name = node.getBaseAttribute('name')
    if (!name) return ''

    const component = new ReactComponentImpl(name, element)
    return component.render()
  }
}
