import { ReadOnlyNode } from 'application/node'
import { formatNodeId } from './utils'
import { Element, Tag } from './html/types'
import { ContainerElementBuilder } from './html/elementContainerBuilder'
import { ContentElementBuilder } from './html/elementContentBuilder'
import { LeafElementBuilder } from './html/elementLeafBuilder'

export class ContentTransformer {
  transform = (node: ReadOnlyNode): Element | null => {
    switch (node.getBaseAttribute('type')) {
      case 'page':
      case 'frame':
      case 'canvas':
      case 'button':
      case 'form':
      case 'anchor':
        return new ContainerElementBuilder()
          .withTag(this.getTag(node))
          .withAttributes({
            id: formatNodeId(node.getId()),
            class: formatNodeId(node.getId()),
          })
          .build()
      case 'input':
        return new LeafElementBuilder()
          .withTag(this.getTag(node))
          .withAttributes({
            id: formatNodeId(node.getId()),
            class: formatNodeId(node.getId()),
          })
          .build()
      case 'paragraph':
        return this.transformText(node)
      case 'image':
        return new LeafElementBuilder()
          .withTag(this.getTag(node))
          .withAttributes({
            id: formatNodeId(node.getId()),
            class: formatNodeId(node.getId()),
          })
          .build()
      case 'content':
        return new ContentElementBuilder()
          .withContent(node.getBaseAttribute('text.content') || '')
          .build()
      default:
        return null
    }
  }

  private transformText = (node: ReadOnlyNode): Element => {
    const container = new ContainerElementBuilder()
      .withAttributes({
        id: formatNodeId(node.getId()),
        class: formatNodeId(node.getId()),
      })
      .withTag(this.getTag(node))
      .build()

    const content = node.getBaseAttribute('text.content')
    if (!content || content.length === 0) return container

    const contentNode = new ContentElementBuilder()
      .withContent(content.replace(/\n/g, '<br>'))
      .build()
    container.addChild(contentNode)

    return container
  }

  private getTag = (node: ReadOnlyNode): Tag => {
    const tag = node.getBaseAttribute('html.tag')
    const type = node.getBaseAttribute('type')
    switch (type) {
      case 'paragraph':
        return tag || 'p'
      case 'button':
        return tag || 'button'
      case 'image':
        return tag || 'img'
      case 'input':
        return tag || 'input'
      case 'form':
        return tag || 'form'
      case 'anchor':
        return tag || 'a'
      default:
        return tag || 'div'
    }
  }
}
