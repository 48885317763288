import { SelectorName, StyleMap } from 'application/attributes'
import { Document, ReadOnlyDocument } from 'application/document'
import { StyleSelector, StyleSelectorBuilder } from './css'
import { ReadOnlyNode } from 'application/node'
import { Tag } from './html/types'

export function formatNodeId(nodeId: string) {
  return `e${nodeId}`
}

export function createSelector(id: string, mode: SelectorName): StyleSelector {
  switch (mode) {
    case 'default':
      return createBaseSelector(id)
    case 'tablet':
      return createMediaSelector(id, 1200)
    case 'landscape':
      return createMediaSelector(id, 768)
    case 'mobile':
      return createMediaSelector(id, 480)
    case 'default hover':
      return createBaseSelector(id, true)
    case 'tablet hover':
      return createMediaSelector(id, 1200, true)
    case 'landscape hover':
      return createMediaSelector(id, 768, true)
    case 'mobile hover':
      return createMediaSelector(id, 480, true)
  }
}

export function createBaseSelector(
  id: string,
  hover: boolean = false
): StyleSelector {
  const builder = new StyleSelectorBuilder()
    .withMode('class')
    .withValue(formatNodeId(id))
  if (hover) builder.withPseudo('hover')
  return builder.build()
}

function createMediaSelector(
  id: string,
  size: number,
  hover: boolean = false
): StyleSelector {
  const builder = new StyleSelectorBuilder()
    .withMode('class')
    .withValue(formatNodeId(id))
    .withMedia({ type: 'media', clause: { mode: 'max', size: size } })
  if (hover) builder.withPseudo('hover')
  return builder.build()
}

export function getCascadedStyles(
  node: ReadOnlyNode,
  mode: SelectorName
): StyleMap {
  const cascaded = selectorOrder.slice(0, selectorOrder.indexOf(mode) + 1)
  const styles: StyleMap = {
    ...node.getDefaultSelector().styles,
  }
  for (const selector of node.getSelectors()) {
    if (cascaded.includes(selector.name)) {
      Object.assign(styles, selector.styles)
    }
  }
  return styles
}

const selectorOrder: SelectorName[] = [
  'default',
  'tablet',
  'landscape',
  'mobile',
  'default hover',
  'tablet hover',
  'landscape hover',
  'mobile hover',
]

export function getTagStyleSelector(tag: Tag) {
  return new StyleSelectorBuilder().withMode('tag').withValue(tag).build()
}

export function getDefaultAttributes(styleMap: StyleMap): StyleMap {
  return styleMap
}

export function nodesInSamePage(
  id1: string,
  id2: string,
  document: Document
): boolean {
  const parent1 = getPageParent(id1, document)
  const parent2 = getPageParent(id2, document)
  if (!parent1 || !parent2) return false
  return parent1.getId() === parent2.getId()
}

function getPageParent(
  id: string,
  document: ReadOnlyDocument
): ReadOnlyNode | undefined {
  let parent = document.getNode(id)
  while (parent && parent.getBaseAttributes()['type'] !== 'page') {
    if (!parent.getParent()) return undefined
    parent = document.getParent(parent)
  }
  return parent
}

export function formatComponentName(name: string): string {
  let formattedName = name.charAt(0).toUpperCase() + name.slice(1)
  formattedName = formattedName.replace(/[^a-zA-Z0-9_]/g, '')
  if (/^[0-9]/.test(formattedName)) formattedName = '_' + formattedName
  return formattedName
}
