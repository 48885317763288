import { Element, Tag } from '../html/types'
import { formatComponentName } from '../utils'
import { ReactComponent } from './types'

export class ReactComponentImpl implements ReactComponent {
  private name: string
  private element: Element

  constructor(name: string, element: Element) {
    this.name = name
    this.element = element
  }

  getName = (): string => {
    return this.name
  }

  render = (): string => {
    const formattedName = formatComponentName(this.name)
    const formattedTag = this.formatReactTagType(this.element.getTag())
    const propsType = `PropsWithChildren<${formattedName}Props>`

    return `
    import React, { PropsWithChildren } from 'react'
    import './${formattedName}.css'

    interface ${formattedName}Props extends React.HTMLAttributes<${formattedTag}> {}

    const ${formattedName}: React.FC<${propsType}> = ({
      children,
      ...props
    }) => {
      return (
        ${this.element.render({
          excludeAttributes: ['id'],
          className: true,
          withProps: true,
          withChildren: true,
        })}
      )
    }

    export { ${formattedName} }
    `
  }

  private formatReactTagType = (tag: Tag | null): string => {
    switch (tag) {
      case 'root':
        return 'HTMLElement'
      case 'div':
        return 'HTMLDivElement'
      case 'p':
        return 'HTMLParagraphElement'
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        return 'HTMLHeadingElement'
      case 'button':
        return 'HTMLButtonElement'
      case 'span':
        return 'HTMLSpanElement'
      case 'img':
        return 'HTMLImageElement'
      case 'input':
        return 'HTMLInputElement'
      case 'form':
        return 'HTMLFormElement'
      case 'a':
        return 'HTMLAnchorElement'
      default:
        return 'HTMLElement'
    }
  }
}
