import { Properties } from '../css'
import { Attributes, Element, ElementRenderOptions, Tag } from './types'
import { attributesToString } from './utils'

export class ContainerElement implements Element {
  private tag: Tag
  private attributes: Partial<Attributes>
  private children: Element[]
  private inlineStyle: Partial<Properties>

  constructor(tag: Tag, attributes: Partial<Attributes>, children: Element[]) {
    this.tag = tag
    this.attributes = attributes
    this.children = children
    this.inlineStyle = {}
  }

  getTag = (): Tag => {
    return this.tag
  }

  addChild = (child: Element, index?: number): boolean => {
    if (this.children.includes(child)) return false

    if (index !== undefined) {
      this.children.splice(index, 0, child)
    } else {
      this.children.push(child)
    }

    return true
  }

  removeChild = (child: Element): number => {
    const index = this.children.indexOf(child)
    if (index === -1) return -1

    this.children.splice(index, 1)
    return index
  }

  getChildren = (): Element[] => {
    return this.children
  }

  getAttribute = <K extends keyof Attributes>(key: K): Attributes[K] | null => {
    return this.attributes[key] ?? null
  }

  setAttribute = <K extends keyof Attributes>(
    key: K,
    value: Attributes[K]
  ): void => {
    this.attributes[key] = value
  }

  removeAttribute = <K extends keyof Attributes>(key: K): void => {
    delete this.attributes[key]
  }

  getInlineStyle = <K extends keyof Properties>(k: K): Properties[K] | null => {
    return this.inlineStyle[k] ?? null
  }

  setInlineStyle = <K extends keyof Properties>(
    k: K,
    v: Properties[K]
  ): void => {
    this.inlineStyle[k] = v
  }

  render = (options?: ElementRenderOptions): string => {
    if (this.hasChildrenProp(options)) {
      return `<${this.tag}${attributesToString(this.attributes, options)}${
        options?.withProps ? ' { ...props }' : ''
      }>{children}</${this.tag}>`
    }

    return `<${this.tag}${attributesToString(this.attributes, options)}${
      options?.withProps ? ' { ...props }' : ''
    }>${this.getChildrenString(options)}</${this.tag}>`
  }

  private getChildrenString = (options?: ElementRenderOptions): string => {
    return this.children
      .map((child) => child.render({ ...options, withProps: false }))
      .join('')
  }

  private hasChildrenProp = (options?: ElementRenderOptions): boolean => {
    return options?.withChildren ?? false
  }
}
