import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import useSelected from 'hooks/editor/useSelected'
import { editor } from 'index'
import { useState } from 'react'
import PublishPopup from './PublishPopup/PublishPopup'

export default function PublishButton() {
  const selected = useSelected()
  const publishPanel = usePanelHelper(editor.getUI().getPublishPanel())

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  if (!publishPanel || !publishPanel.settings || selected.length === 0)
    return null

  return (
    <>
      <CTAButton
        width={96}
        text={'Publish'}
        onClick={() => setIsPopupOpen(!isPopupOpen)}
      />
      {isPopupOpen && (
        <PublishPopup
          selected={selected}
          publishing={publishPanel.settings.publishing}
          publish={publishPanel.handlers.publish}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </>
  )
}
