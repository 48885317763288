import { Attributes, ElementRenderOptions } from './types'

export function attributesToString(
  attributes: Partial<Attributes>,
  renderOptions?: ElementRenderOptions
): string {
  return Object.entries(attributes)
    .filter(
      ([key, value]) =>
        value !== undefined &&
        !renderOptions?.excludeAttributes?.includes(key as keyof Attributes)
    )
    .map(([key, value]) => {
      if (renderOptions?.className && key === 'class') {
        return ` className="${value}"`
      }
      return ` ${key}="${value}"`
    })
    .join('')
}
