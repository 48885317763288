import { DesignColor, ThemeColorKey } from 'themes'
import {
  panelBorderFull,
  panelPosition,
  panelShadow,
  panelWidth,
} from '../constants'

interface PanelContainerProps {
  id?: string

  panelRef?: React.RefObject<HTMLDivElement>

  position?: panelPosition
  width?: panelWidth
  height?: number | string
  paddingH?: 0 | 10 | 16
  gap?: number
  direction?: 'column' | 'row'

  popup?: boolean

  children?: React.ReactNode
  zIndex?: number

  backgroundColor?: ThemeColorKey
  overflow?: 'hidden' | 'auto'
}

export default function PanelContainer({
  id,
  panelRef,
  position = {},
  width = 232,
  paddingH = 0,
  direction = 'column',
  gap = 0,
  height,
  popup,
  children,
  zIndex,
  backgroundColor,
  overflow,
}: PanelContainerProps) {
  return (
    <div
      id={id}
      className={popup ? 'popup' : undefined}
      ref={panelRef}
      style={{
        ...position,
        width: getPanelWidth(width),
        height: height,
        padding: `0 ${paddingH}px`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        justifyContent: 'start',
        gap: gap,
        boxShadow: [panelShadow, panelBorderFull].join(', '),
        background: DesignColor(
          backgroundColor ? backgroundColor : 'panelBackground'
        ),
        borderRadius: 8,
        pointerEvents: 'auto',
        zIndex: zIndex,
        overflow: overflow,
      }}
    >
      {children}
    </div>
  )
}

function getPanelWidth(width: panelWidth) {
  switch (width) {
    case 'fill':
      return '100%'
    case 'hug':
      return 'fit-content'
    default:
      return width
  }
}
